<template>
	<v-alert type="error">Sorry, you are not authorized to view the requested page.</v-alert>
</template>

<script>
	export default {
		name: 'UnauthorizedView'
	}
</script>

<style lang="scss" scoped>

</style>