var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filters)?_c('v-form',{ref:"filtersForm",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card',{attrs:{"outlined":"","tile":"","id":"explore_filters"}},[_c('v-card-title',[_c('span',{staticClass:"text-h6 font-weight-light"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-filter-variant")]),_vm._v(" "+_vm._s(_vm.$t('tools.explore.filters'))+" ")],1)]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-autocomplete',{attrs:{"label":_vm.filters.locationTypeFilter.type['name_' + _vm.locale],"placeholder":_vm.filters.locationTypeFilter.type['name_' + _vm.locale],"items":_vm.filters.locationTypeFilter.options,"item-text":'name_' + _vm.locale,"item-value":"id","item-disabled":"disabled","hide-no-data":"","flat":"","dense":"","rules":[function (v) { return !!v || _vm.$t('tools.common.make_selection'); }]},on:{"change":_vm.selectLocationType},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"title":item.disabled ? !_vm.indicator.aggregable ? _vm.$t('data.not_aggregable') : _vm.$t('tools.explore.no_data_custom_locations') : null},domProps:{"innerHTML":_vm._s(parent.genFilteredText(item['name_' + _vm.locale]))}})],1)]}}],null,false,88357073),model:{value:(_vm.selectedLocationType),callback:function ($$v) {_vm.selectedLocationType=$$v},expression:"selectedLocationType"}}),_c('v-autocomplete',{attrs:{"label":_vm.filters.locationFilter.type['name_' + _vm.locale],"placeholder":_vm.filters.locationFilter.type['name_' + _vm.locale],"items":_vm.locationFilterOptions,"item-text":'name_' + _vm.locale,"item-value":"id","item-disabled":"disabled","hide-no-data":"","flat":"","dense":"","rules":[function (v) { return !!v || _vm.$t('tools.common.make_selection'); }]},on:{"change":_vm.requestApply},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"title":item.disabled ? _vm.$t('tools.explore.no_data_custom_location') : null},domProps:{"innerHTML":_vm._s(parent.genFilteredText(item['name_' + _vm.locale]))}})],1)]}}],null,false,2910355724),model:{value:(_vm.selectedLocation),callback:function ($$v) {_vm.selectedLocation=$$v},expression:"selectedLocation"}}),_c('v-autocomplete',{attrs:{"label":_vm.filters.yearFilter.type['name_' + _vm.locale],"placeholder":_vm.filters.yearFilter.type['name_' + _vm.locale],"items":_vm.yearOptions,"item-text":'name_' + _vm.locale,"item-value":"name_en","item-disabled":"disabled","hide-no-data":"","flat":"","dense":"","rules":[function (v) { return !!v || _vm.$t('tools.common.make_selection'); }]},on:{"change":_vm.requestApply},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"title":item.disabled ? _vm.$t('tools.explore.no_data_location_year') : null},domProps:{"innerHTML":_vm._s(parent.genFilteredText(item['name_' + _vm.locale]))}})],1)]}}],null,false,110615152),model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}}),_vm._l((_vm.filters.indicatorFilters),function(filter){return [_c('v-autocomplete',{key:filter.type.id,attrs:{"label":filter.type['name_' + _vm.locale],"placeholder":filter.type['name_' + _vm.locale],"items":filter.options,"item-text":'name_' + _vm.locale,"return-object":"","hide-no-data":"","flat":"","dense":"","rules":[function (v) { return !!v || _vm.$t('tools.common.make_selection'); }],"disabled":!_vm.availableFilterCombos[filter.type.id]},on:{"change":_vm.selectIndicatorFilter},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var parent = ref.parent;
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"title":!_vm.availableFilterCombos[filter.type.id] ? _vm.$t('tools.common.filter_unavailable') : null},domProps:{"innerHTML":_vm._s(parent.genFilteredText(item['name_' + _vm.locale]))}})],1)]}}],null,true),model:{value:(_vm.indicatorFilterSelections[filter.type.id]),callback:function ($$v) {_vm.$set(_vm.indicatorFilterSelections, filter.type.id, $$v)},expression:"indicatorFilterSelections[filter.type.id]"}})]})],2),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"font-weight-bold",staticStyle:{"font-size":"14pt"},attrs:{"rounded":"","color":"green","disabled":!_vm.applyNeeded,"data-html2canvas-ignore":""},on:{"click":_vm.applyFilters}},[_vm._v(" "+_vm._s(_vm.$t('tools.explore.apply_filters'))+" ")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }